import {
  container,
  main,
  mainRaised,
  textCenter,
  title,
  whiteColor,
} from "assets/jss/material-kit-pro-react.jsx"

const registrationOnlinePageStyle = (theme) => ({
  container: {
    ...container,
    zIndex: "2",
    position: "relative",
  },
  main: {
    ...main,
    ...mainRaised,
    margin: "-70px 80px 100px",
    padding: "70px 0px",
    [theme.breakpoints.down("sm")]: {
      margin: "0px 0px 0px",
    },
  },
  pageTitle: {
    ...title,
    ...textCenter,
    color: whiteColor,
    [theme.breakpoints.down("sm")]: {
      fontSize: "3rem",
    },
    "@media (max-width: 374px)": {
      fontSize: "2.75rem",
    },
  },
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepContainer: {
    "& text": {
      fill: whiteColor,
    },
  },
})

export default registrationOnlinePageStyle
