import React, { useEffect, useRef } from "react"
import axios from "axios"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"
// core components
import CustomHeader from "components/Header/CustomHeader.jsx"
import CustomFooter from "components/Footer/CustomFooter.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import ReactParallax from "components/Parallax/Parallax.jsx"
import SEOHeader from "components/SEOHeader/SEOHeader.jsx"
// Gatsby Libraies
import { graphql, useStaticQuery } from "gatsby"
// page sections
import SectionFormComplete from "./Sections/SectionFormComplete.jsx"
import SectionOnlineForm from "./Sections/SectionOnlineForm.jsx"
import SectionOnlinePayment from "./Sections/SectionOnlinePayment.jsx"
import SectionTimer from "../LandingPage/Sections/SectionTimer.jsx"

// page styles
import registrationOnlinePageStyle from "assets/jss/material-kit-pro-react/views/registrationOnlinePageStyle.jsx"
const useStyles = makeStyles(registrationOnlinePageStyle)

const registrationFormEndpoint =
  "https://cznh3fqbab.execute-api.us-west-2.amazonaws.com/prod/register"

export default function RegistrationOnlinePage() {
  const [formData, setFormData] = React.useState({
    firstName: "",
    lastName: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    email: "",
    phoneNumber: "",
    neighbors: "",
    competitions: {
      casi: false,
      traditional: false,
      salsa: false,
      showmanship: false,
      chiliName: "",
    },
    boothNumber: "",
  })
  const [formCompleted, setFormCompleted] = React.useState(false)
  const [formError, setFormError] = React.useState(false)
  const [paymentName, setPaymentName] = React.useState("")
  const [paymentCompleted, setPaymentCompleted] = React.useState(false)
  const [paymentError, setPaymentError] = React.useState(false)
  const [submitError, setSubmitError] = React.useState(false)
  const [activeStep, setActiveStep] = React.useState(0)
  const isMounted = useRef(true)

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    window.scrollTo(0, 0)
  }

  const handleBack = () => {
    setFormCompleted(false)
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
    window.scrollTo(0, 0)
  }

  const submitFormData = async () => {
    try {
      await axios.post(registrationFormEndpoint, formData)
      if (isMounted.current) {
        handleNext()
      }
    } catch (error) {
      if (isMounted.current) {
        setSubmitError(true)
        setFormError(true)
      }
    }
  }

  const handleSubmit = () => {
    setFormCompleted(true)
  }

  useEffect(() => {
    if (formCompleted) {
      submitFormData()
    }
  }, [formCompleted, formData])

  const { registrationOnlineBackground } = useStaticQuery(graphql`
    query getRegistrationOnlineBackground {
      registrationOnlineBackground: file(
        relativePath: { eq: "banners/chili-ingredients-registration.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  const classes = useStyles()
  return (
    <div>
      <SEOHeader
        title="Register Online - Chilimania"
        description="This is the online registration form for the Chilimania CASI and Traditional Cook-Offs as well as the Salsa Contest"
      />
      <CustomHeader />
      <ReactParallax
        imageData={registrationOnlineBackground.childImageSharp.gatsbyImageData}
        imageTitle={"Registration Online Banner"}
        filter="dark"
        imageStyle={{ height: "75vh" }}
        small
      >
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem xs={12} sm={12} md={12}>
              <h1 className={classes.pageTitle}>
                Chilimania Online Registration
              </h1>
            </GridItem>
          </GridContainer>
        </div>
      </ReactParallax>
      <div className={classes.main}>
        <GridContainer
          justifyContent="center"
          className={classes.stepContainer}
        >
          <GridItem xs={12}>
            <Stepper activeStep={activeStep} alternativeLabel>
              <Step key="details">
                <StepLabel error={formError}>Details</StepLabel>
              </Step>
              <Step key="payment">
                <StepLabel error={paymentError}>Payment</StepLabel>
              </Step>
              <Step key="finish">
                <StepLabel error={submitError || paymentError}>
                  Finish
                </StepLabel>
              </Step>
            </Stepper>
            {!formCompleted && (
              <SectionOnlineForm
                handleNext={handleNext}
                formData={formData}
                setFormData={setFormData}
                setFormCompleted={setFormCompleted}
                formError={formError}
                setFormError={setFormError}
              />
            )}
            {formCompleted && !paymentCompleted && (
              <SectionOnlinePayment
                handleBack={handleBack}
                handleNext={handleNext}
                handleSubmit={handleSubmit}
                setPaymentName={setPaymentName}
                setPaymentCompleted={setPaymentCompleted}
                paymentError={paymentError}
                setPaymentError={setPaymentError}
              />
            )}
            {formCompleted && paymentCompleted && (
              <SectionFormComplete
                handleNext={handleNext}
                paymentName={paymentName}
                submitError={submitError}
              />
            )}
          </GridItem>
        </GridContainer>
      </div>
      <SectionTimer />
      <CustomFooter />
    </div>
  )
}
